<template>
  <div class="readme-article">
    <h1 id="教务配置">2.教务配置</h1>
    <h2 id="学员期数">2.1学员期数</h2>
    <p>
      学员期数列表，可配置期数的项目分类和计划招生时间。
    </p>
    <p>
      <img src="@/assets/img/student/config2-1.png" />
    </p>
    <h2 id="报考期数">2.2报考期数</h2>
    <p>
      报考期数列表，报考期数配置，包括报考时间，报考类型，报考科目等。
    </p>
    <p>
      <img src="@/assets/img/student/config2-2.png" />
    </p>
    <h2 id="替考计划">2.3替考计划</h2>
    <p>
      学员期数列表，可配置期数的项目分类和计划招生时间。
    </p>
    <p>
      <img src="@/assets/img/student/config2-3.png" />
    </p>
    <h2 id="考区">2.4考区</h2>
    <p>
      考试区域配置，多级化区域
    </p>
    <h2 id="学员标签">2.5学员标签</h2>
    <p>
      查看学员标签列表，学员标签编辑，可在教务模块中的学员详情里给学员添加标签。
    </p>
    <p>
      <img src="@/assets/img/student/config2-4.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Student0-2",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>